/* .carousel-parent{
  
} */


.carousel-parent{

    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
h1 {
    color: var(--dark-purple);
}

.gallery{
    height: 150px;
    width: 150px;
    position: relative;
    transform-style: preserve-3d;
    animation: gallery 20s linear infinite;
}

.gallery:hover{
    animation-play-state: paused;
}
.gallery:hover svg{
    opacity: 0.2;
}


.gallery span{
    margin-top: 50px;
    height: 100%;
    width:80%;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
    transform-origin: center;
    transform: rotateY(calc(var(--i) * 40deg)) translateZ(100px);
    transition: all 1s;
}


.gallery span svg{
    height: 50%;
    width:50%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    -webkit-box-reflect: below 1px linear-gradient(transparent , transparent , rgba(0, 0, 0, 0.5));
    transition: all 1s;
}


.gallery span svg:hover{
    cursor: pointer;
    transition: all 1s;
    opacity: 1;
    transform: scale(1.2);
}


@media only screen and (min-width: 800px) {
    .gallery{
        height: 150px;
        width: 150px;
    }
    .gallery span {
        height: 100%;
        width:100%;
        transform: rotateY(calc(var(--i) * 40deg)) translateZ(200px);
    }
    h1 {
        font-size: 33px;
    }
  }

  @media only screen and (min-width: 800px) {
    .gallery{
        height: 200px;
        width: 200px;
    }
    .gallery span {
        /* height: 100%; */
        width:70%;
        transform: rotateY(calc(var(--i) * 40deg)) translateZ(400px);
    }

    .gallery span svg{
        height: 100%;
        width: 100%;
    }
    h1 {
        font-size: 33px;
    }
  }


@keyframes gallery{

    0%{
       transform: perspective(1000px) rotateY(0deg);
    }
    100%{
        transform: perspective(1000px) rotateY(360deg);
    }

}