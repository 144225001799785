.navibar {
    background-color: var(--dark-purple);
    font-size: 15px;
}


.nav-text {
    color: var(--lighter-gray) !important;
}
.nav-text:hover {
    color: var(--light-purple) !important;
}

.flex-end {
    justify-content: flex-end;
}