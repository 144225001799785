@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
:root {
  --dark-purple: #4E3C51;
  --light-purple: #B6A5D0;
  --darker-gray: #A9AAAA;
  --lighter-gray: #E3E3E3;
}
.flex-with-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("/public/custom.svg");
  min-height: 100vh;
  background-size: cover;
  object-fit: fill;
  background-repeat: no-repeat;
}
body {
  background-color: var(--light-purple) !important;
}
h1 {
  font-family: 'Righteous', cursive;
}

img {
  width: 30rem;
}



#home {
  background-color: var(--light-purple);
  margin: 0;

}

#project {
  width: 80%;
  margin: 0 auto;
}


.main-container {
  margin: 0 auto !important;
  min-height: 100vh;
  margin-top: 4%;
  display: flex;
  flex-wrap: wrap;
  
}

.font {
  font-family: 'Righteous', cursive;
}
.container {
  min-height: 100vh;
  margin-top: 2%;
  padding: 2%;
  border-radius: 10px;
  width: 80%;

}

.App {
  text-align: center;
  margin-top: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
